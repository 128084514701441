/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { Heading } from '@lmig/lmds-react';
import { IconSmartphone, IconPhone, IconEmail } from '@lmig/lmds-react/icons';
import { formatPhoneNumber } from "@lmig/dotcom-aspect-comparion-helpers/phoneNumber";
import Dot from '../Dot';
import Rating from '../Rating';
import AgentImageSocials from './AgentImageSocials';

const { className: headingClass, styles: headingStyles } = css.resolve`
  @import './global-styles/tokens';
  .lm-Heading {
    font-size: 1.875rem;
    margin: 1rem 0 0;
  }
  @media screen and (min-width: $lm-breakpoint-md-min) {
    .lm-Heading {
      font-size: 2rem;
      margin: 0;
    }
  }
`;

const AgentHero = ({ agent }) => {
  const secondaryPhone = agent.primaryPhone?.number;
  let agentName = agent.preferredName || agent.firstName;

  if (/\s/g.test(agentName)) {
    agentName = agent.displayName;
  }

  const lessPadding = agent.phones?.cell || agent.videoUrl ? '' : 'heroLessPaddingBottom';
  const title = agent.title ? `Comparion ${agent.title}` : 'Comparion Insurance Agent';
  const agentCell = formatPhoneNumber(agent.phones?.cell?.number || null);
  const agentSecondary = formatPhoneNumber(secondaryPhone || null);

  return (
    <div className={`heroWrapper ${lessPadding}`}>
      <div className="headShotWrapper">
        <AgentImageSocials image={agent.image || {}} socials={agent.social} />
      </div>
      <div className="heroInfo">
        <Heading tag="h1" type="h2-bold" className={headingClass}>{agent.displayName}</Heading>
        <Heading tag="h2" type="h2-bold" className={headingClass}>
          <p className="heroSubHeading">{title}
            {!agent.isRemote && `\u00A0| ${agent.offices[0].address.city}, ${agent.offices[0].address.state.code}`}
          </p>
        </Heading>
        <Dot fill />
        <div className="heroQuote">{`"${agent.quote || "Expert insurance advice from a member of your local community"}"`}</div>
        <div className="heroContactLinks">
          {agentCell && (<a className='ctaLink' href={`tel:${agentCell}`}>
            <div className="ctaIconWrapper">
              <IconSmartphone size={{ base: '24' }} />
              <span className="ctaText" itemProp="telephone">{agentCell}</span>
            </div>
          </a>)}
          {agentSecondary && (<a className='ctaLink' href={`tel:${agentSecondary}`}>
            <div className="ctaIconWrapper">
              <IconPhone size={{ base: '24' }} />
              <span className="ctaText" itemProp="telephone">{agentSecondary}</span>
            </div>
          </a>)}
          <a className='ctaLink' href={`mailto:${agent.email}`}>
            <div className="ctaIconWrapper">
              <IconEmail size={{ base: '24' }} />
              <span className="ctaText">{`Email ${agentName}`}</span>
            </div>
          </a>
        </div>
        <div className="reviewsWrapper">
          <Rating rating={agent.rating} count={agent.reviews?.length} isLinked />
        </div>
      </div>
      <style jsx>{`@import './AgentHero.scoped.scss';`}</style>
      {headingStyles}
    </div>
  );
};

AgentHero.propTypes = {
  agent: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number
    }),
    displayName: PropTypes.string,
    offices: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
    })),
    phones: PropTypes.shape({
      primary: PropTypes.shape({
        number: PropTypes.string
      })
    }),
    social: PropTypes.shape({
      facebook: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      twitter: PropTypes.string,
    }),
    email: PropTypes.string
  }).isRequired
};


export default AgentHero;